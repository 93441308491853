import platform from './util/get-plateform';
const script = document.createElement('script');
const origin = window.location.origin.includes('hilook.com.cn');
const originPre = window.location.origin.includes('higroup-ihaier-pre.hilook.com.cn');
let staffToken = sessionStorage.getItem('staff-token');
console.log(window.location.href);
if (origin && !staffToken && window.location.href.indexOf('xueId') == -1) {
  script.src = 'https://r.haier.net/assets/overlay/dts-fe/online-popup/index.js';
} else if (!staffToken && window.location.href.indexOf('xueId') == -1) {
  script.src = 'https://r.haier.net/assets/daily/dts-fe/online-popup/2.0.1/index.js';
}
document.head.appendChild(script);
export default {
  mounted() {
    // 禁用右键菜单
    document.addEventListener('contextmenu', this.handleContextMenu);
  },
  unmounted() {
    // 移除事件监听
    document.removeEventListener('contextmenu', this.handleContextMenu);
  },
  methods: {
    handleContextMenu(e) {
      const target = e.target;
      if (target.tagName.toLowerCase() === 'img' && !platform.isMobile()) {
        e.preventDefault();
        return false;
      }
    }
  }
};